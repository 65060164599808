import { default as _91choice_93B397VtZwhZMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/aanbod-optie/[propertyId]/[hash]/[choice].vue?macro=true";
import { default as indexM8w64VY12hMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/aanbod/index.vue?macro=true";
import { default as indexSH1zdRq6p4Meta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/claims-en-opties/index.vue?macro=true";
import { default as _91hash_93iemsyO49znMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/einde-looptijd-lening/[hash].vue?macro=true";
import { default as indexjHID5b6hH4Meta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/financieel-overzicht/financien/index.vue?macro=true";
import { default as indexqeKuX21AZEMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/financieel-overzicht/index.vue?macro=true";
import { default as indexH1RyCzF1gHMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/financieel-overzicht/rente/index.vue?macro=true";
import { default as indexwBbpbUlYNFMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/fonds-optie/[property_id]/[hash]/[type]/index.vue?macro=true";
import { default as indexncrOpDk3zAMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/hypotheek-overzicht/index.vue?macro=true";
import { default as indexbXdCQyroE8Meta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/hypotheken/[id]/index.vue?macro=true";
import { default as indexuCL52mdvOZMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/hypotheken/index.vue?macro=true";
import { default as indexwQWwzX3d7MMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/index.vue?macro=true";
import { default as indexssaw3oxa4UMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/instellingen/index.vue?macro=true";
import { default as indexolp7JGVk5lMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/investeringen/[id]/index.vue?macro=true";
import { default as indexcioQyo4h7UMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/investeringen/index.vue?macro=true";
import { default as indexwstkjbiaggMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/login/index.vue?macro=true";
import { default as reset_45wachtwoordShtD7BE8DCMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/login/reset-wachtwoord.vue?macro=true";
import { default as wachtwoord_45vergetendIHv7ig6fQMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/login/wachtwoord-vergeten.vue?macro=true";
import { default as indexDd20Kez3edMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-aanvragen/[offerNumber]/herkomst/index.vue?macro=true";
import { default as indexzd59nUMxSrMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-aanvragen/[offerNumber]/index.vue?macro=true";
import { default as index8Gdqt30rpmMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-aanvragen/index.vue?macro=true";
import { default as index1avXzuNODDMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-organisaties/[companyId]/iban-overzicht/index.vue?macro=true";
import { default as indexhMw5nLACiMMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-organisaties/bedrijfs-gegevens/index.vue?macro=true";
import { default as indexcHmiujHBLNMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-organisaties/index.vue?macro=true";
import { default as indexg4VmkzbvKxMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-partnerplan/[token]/[choice]/index.vue?macro=true";
import { default as indexcUNd7tf5i3Meta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-partnerplan/index.vue?macro=true";
import { default as indexqsGK3v3CE2Meta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-profiel/digitaal-identificeren/index.vue?macro=true";
import { default as indexmxtWoZih3lMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-profiel/iban-overzicht/index.vue?macro=true";
import { default as indexqygFE6nOI1Meta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-profiel/index.vue?macro=true";
import { default as indexA1zmBEOfwuMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-profiel/naw-gegevens/index.vue?macro=true";
import { default as indexbRC0FZjFPlMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mogelijk-fonds/[id]/deelname/index.vue?macro=true";
import { default as indexGKbCM3HM5AMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mogelijk-fonds/[id]/documenten/index.vue?macro=true";
import { default as indexrgDotvdPdgMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mogelijk-fonds/[id]/index.vue?macro=true";
import { default as indextIUpDhxCf3Meta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mogelijk-fonds/[id]/inschrijving/index.vue?macro=true";
import { default as indexy7IMmothDAMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mogelijk-fonds/[id]/ondertekenen/index.vue?macro=true";
import { default as indexM3FQe8YIVZMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mogelijk-fonds/[id]/overmaken/index.vue?macro=true";
import { default as indexnGG4oqJjquMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mogelijk-fonds/index.vue?macro=true";
import { default as errorl4GapDjml4Meta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/ockto/[hash]/error.vue?macro=true";
import { default as indexDAgjycjDx0Meta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/ockto/[hash]/index.vue?macro=true";
import { default as qrcodeS6R8X8gDaNMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/ockto/[hash]/qrcode.vue?macro=true";
import { default as succeswuoI46DVRMMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/ockto/[hash]/succes.vue?macro=true";
import { default as _91token_935uXL8nSVjxMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/onboarding/[signing_id]/[token].vue?macro=true";
import { default as _91slug_93Y3S2q00l9DMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/onboarding/[slug].vue?macro=true";
import { default as aanvraagblflfV030CMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/onboarding/aanvraag.vue?macro=true";
import { default as _91experience_930TNIz3LAbEMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/onboarding/bedankt/[experience].vue?macro=true";
import { default as _91token_93V8SXHs3uGAMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/onfido/[token].vue?macro=true";
import { default as _91hash_93kd76prWZ1tMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/passeren/[hash].vue?macro=true";
import { default as indexmwbb9VPXjAMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/privacy-verklaring/index.vue?macro=true";
import { default as _91hash_93zxWrTiNXFHMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/rentevoorstel/[hash].vue?macro=true";
import { default as _91fundKey_93Wteg3JiTUYMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/sso/[exchangeToken]/[fundKey].vue?macro=true";
import { default as aanvraagU1OiJkhWK1Meta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/sso/[exchangeToken]/onboarding/aanvraag.vue?macro=true";
import { default as indexEcLK1DgfNzMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/sso/[exchangeToken]/onboarding/index.vue?macro=true";
import { default as _91id_93U43tVIK1x0Meta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/sso/[token]/[id].vue?macro=true";
import { default as indexvwJKHv3G2wMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/sso/[token]/index.vue?macro=true";
import { default as _91hash_939vPfT7SYFoMeta } from "/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/verzekeringen/[hash].vue?macro=true";
export default [
  {
    name: _91choice_93B397VtZwhZMeta?.name ?? "aanbod-optie-propertyId-hash-choice",
    path: _91choice_93B397VtZwhZMeta?.path ?? "/aanbod-optie/:propertyId()/:hash()/:choice()",
    meta: _91choice_93B397VtZwhZMeta || {},
    alias: _91choice_93B397VtZwhZMeta?.alias || [],
    redirect: _91choice_93B397VtZwhZMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/aanbod-optie/[propertyId]/[hash]/[choice].vue").then(m => m.default || m)
  },
  {
    name: indexM8w64VY12hMeta?.name ?? "aanbod",
    path: indexM8w64VY12hMeta?.path ?? "/aanbod",
    meta: indexM8w64VY12hMeta || {},
    alias: indexM8w64VY12hMeta?.alias || [],
    redirect: indexM8w64VY12hMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/aanbod/index.vue").then(m => m.default || m)
  },
  {
    name: indexSH1zdRq6p4Meta?.name ?? "claims-en-opties",
    path: indexSH1zdRq6p4Meta?.path ?? "/claims-en-opties",
    meta: indexSH1zdRq6p4Meta || {},
    alias: indexSH1zdRq6p4Meta?.alias || [],
    redirect: indexSH1zdRq6p4Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/claims-en-opties/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93iemsyO49znMeta?.name ?? "einde-looptijd-lening-hash",
    path: _91hash_93iemsyO49znMeta?.path ?? "/einde-looptijd-lening/:hash()",
    meta: _91hash_93iemsyO49znMeta || {},
    alias: _91hash_93iemsyO49znMeta?.alias || [],
    redirect: _91hash_93iemsyO49znMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/einde-looptijd-lening/[hash].vue").then(m => m.default || m)
  },
  {
    name: indexjHID5b6hH4Meta?.name ?? "financieel-overzicht-financien",
    path: indexjHID5b6hH4Meta?.path ?? "/financieel-overzicht/financien",
    meta: indexjHID5b6hH4Meta || {},
    alias: indexjHID5b6hH4Meta?.alias || [],
    redirect: indexjHID5b6hH4Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/financieel-overzicht/financien/index.vue").then(m => m.default || m)
  },
  {
    name: indexqeKuX21AZEMeta?.name ?? "financieel-overzicht",
    path: indexqeKuX21AZEMeta?.path ?? "/financieel-overzicht",
    meta: indexqeKuX21AZEMeta || {},
    alias: indexqeKuX21AZEMeta?.alias || [],
    redirect: indexqeKuX21AZEMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/financieel-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexH1RyCzF1gHMeta?.name ?? "financieel-overzicht-rente",
    path: indexH1RyCzF1gHMeta?.path ?? "/financieel-overzicht/rente",
    meta: indexH1RyCzF1gHMeta || {},
    alias: indexH1RyCzF1gHMeta?.alias || [],
    redirect: indexH1RyCzF1gHMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/financieel-overzicht/rente/index.vue").then(m => m.default || m)
  },
  {
    name: indexwBbpbUlYNFMeta?.name ?? "fonds-optie-property_id-hash-type",
    path: indexwBbpbUlYNFMeta?.path ?? "/fonds-optie/:property_id()/:hash()/:type()",
    meta: indexwBbpbUlYNFMeta || {},
    alias: indexwBbpbUlYNFMeta?.alias || [],
    redirect: indexwBbpbUlYNFMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/fonds-optie/[property_id]/[hash]/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: indexncrOpDk3zAMeta?.name ?? "hypotheek-overzicht",
    path: indexncrOpDk3zAMeta?.path ?? "/hypotheek-overzicht",
    meta: indexncrOpDk3zAMeta || {},
    alias: indexncrOpDk3zAMeta?.alias || [],
    redirect: indexncrOpDk3zAMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/hypotheek-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexbXdCQyroE8Meta?.name ?? "hypotheken-id",
    path: indexbXdCQyroE8Meta?.path ?? "/hypotheken/:id()",
    meta: indexbXdCQyroE8Meta || {},
    alias: indexbXdCQyroE8Meta?.alias || [],
    redirect: indexbXdCQyroE8Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/hypotheken/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexuCL52mdvOZMeta?.name ?? "hypotheken",
    path: indexuCL52mdvOZMeta?.path ?? "/hypotheken",
    meta: indexuCL52mdvOZMeta || {},
    alias: indexuCL52mdvOZMeta?.alias || [],
    redirect: indexuCL52mdvOZMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/hypotheken/index.vue").then(m => m.default || m)
  },
  {
    name: indexwQWwzX3d7MMeta?.name ?? "index",
    path: indexwQWwzX3d7MMeta?.path ?? "/",
    meta: indexwQWwzX3d7MMeta || {},
    alias: indexwQWwzX3d7MMeta?.alias || [],
    redirect: indexwQWwzX3d7MMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexssaw3oxa4UMeta?.name ?? "instellingen",
    path: indexssaw3oxa4UMeta?.path ?? "/instellingen",
    meta: indexssaw3oxa4UMeta || {},
    alias: indexssaw3oxa4UMeta?.alias || [],
    redirect: indexssaw3oxa4UMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/instellingen/index.vue").then(m => m.default || m)
  },
  {
    name: indexolp7JGVk5lMeta?.name ?? "investeringen-id",
    path: indexolp7JGVk5lMeta?.path ?? "/investeringen/:id()",
    meta: indexolp7JGVk5lMeta || {},
    alias: indexolp7JGVk5lMeta?.alias || [],
    redirect: indexolp7JGVk5lMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/investeringen/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexcioQyo4h7UMeta?.name ?? "investeringen",
    path: indexcioQyo4h7UMeta?.path ?? "/investeringen",
    meta: indexcioQyo4h7UMeta || {},
    alias: indexcioQyo4h7UMeta?.alias || [],
    redirect: indexcioQyo4h7UMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/investeringen/index.vue").then(m => m.default || m)
  },
  {
    name: indexwstkjbiaggMeta?.name ?? "login",
    path: indexwstkjbiaggMeta?.path ?? "/login",
    meta: indexwstkjbiaggMeta || {},
    alias: indexwstkjbiaggMeta?.alias || [],
    redirect: indexwstkjbiaggMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45wachtwoordShtD7BE8DCMeta?.name ?? "login-reset-wachtwoord",
    path: reset_45wachtwoordShtD7BE8DCMeta?.path ?? "/login/reset-wachtwoord",
    meta: reset_45wachtwoordShtD7BE8DCMeta || {},
    alias: reset_45wachtwoordShtD7BE8DCMeta?.alias || [],
    redirect: reset_45wachtwoordShtD7BE8DCMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/login/reset-wachtwoord.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45vergetendIHv7ig6fQMeta?.name ?? "login-wachtwoord-vergeten",
    path: wachtwoord_45vergetendIHv7ig6fQMeta?.path ?? "/login/wachtwoord-vergeten",
    meta: wachtwoord_45vergetendIHv7ig6fQMeta || {},
    alias: wachtwoord_45vergetendIHv7ig6fQMeta?.alias || [],
    redirect: wachtwoord_45vergetendIHv7ig6fQMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/login/wachtwoord-vergeten.vue").then(m => m.default || m)
  },
  {
    name: indexDd20Kez3edMeta?.name ?? "mijn-aanvragen-offerNumber-herkomst",
    path: indexDd20Kez3edMeta?.path ?? "/mijn-aanvragen/:offerNumber()/herkomst",
    meta: indexDd20Kez3edMeta || {},
    alias: indexDd20Kez3edMeta?.alias || [],
    redirect: indexDd20Kez3edMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-aanvragen/[offerNumber]/herkomst/index.vue").then(m => m.default || m)
  },
  {
    name: indexzd59nUMxSrMeta?.name ?? "mijn-aanvragen-offerNumber",
    path: indexzd59nUMxSrMeta?.path ?? "/mijn-aanvragen/:offerNumber()",
    meta: indexzd59nUMxSrMeta || {},
    alias: indexzd59nUMxSrMeta?.alias || [],
    redirect: indexzd59nUMxSrMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-aanvragen/[offerNumber]/index.vue").then(m => m.default || m)
  },
  {
    name: index8Gdqt30rpmMeta?.name ?? "mijn-aanvragen",
    path: index8Gdqt30rpmMeta?.path ?? "/mijn-aanvragen",
    meta: index8Gdqt30rpmMeta || {},
    alias: index8Gdqt30rpmMeta?.alias || [],
    redirect: index8Gdqt30rpmMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-aanvragen/index.vue").then(m => m.default || m)
  },
  {
    name: index1avXzuNODDMeta?.name ?? "mijn-organisaties-companyId-iban-overzicht",
    path: index1avXzuNODDMeta?.path ?? "/mijn-organisaties/:companyId()/iban-overzicht",
    meta: index1avXzuNODDMeta || {},
    alias: index1avXzuNODDMeta?.alias || [],
    redirect: index1avXzuNODDMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-organisaties/[companyId]/iban-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexhMw5nLACiMMeta?.name ?? "mijn-organisaties-bedrijfs-gegevens",
    path: indexhMw5nLACiMMeta?.path ?? "/mijn-organisaties/bedrijfs-gegevens",
    meta: indexhMw5nLACiMMeta || {},
    alias: indexhMw5nLACiMMeta?.alias || [],
    redirect: indexhMw5nLACiMMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-organisaties/bedrijfs-gegevens/index.vue").then(m => m.default || m)
  },
  {
    name: indexcHmiujHBLNMeta?.name ?? "mijn-organisaties",
    path: indexcHmiujHBLNMeta?.path ?? "/mijn-organisaties",
    meta: indexcHmiujHBLNMeta || {},
    alias: indexcHmiujHBLNMeta?.alias || [],
    redirect: indexcHmiujHBLNMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-organisaties/index.vue").then(m => m.default || m)
  },
  {
    name: indexg4VmkzbvKxMeta?.name ?? "mijn-partnerplan-token-choice",
    path: indexg4VmkzbvKxMeta?.path ?? "/mijn-partnerplan/:token()/:choice()",
    meta: indexg4VmkzbvKxMeta || {},
    alias: indexg4VmkzbvKxMeta?.alias || [],
    redirect: indexg4VmkzbvKxMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-partnerplan/[token]/[choice]/index.vue").then(m => m.default || m)
  },
  {
    name: indexcUNd7tf5i3Meta?.name ?? "mijn-partnerplan",
    path: indexcUNd7tf5i3Meta?.path ?? "/mijn-partnerplan",
    meta: indexcUNd7tf5i3Meta || {},
    alias: indexcUNd7tf5i3Meta?.alias || [],
    redirect: indexcUNd7tf5i3Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-partnerplan/index.vue").then(m => m.default || m)
  },
  {
    name: indexqsGK3v3CE2Meta?.name ?? "mijn-profiel-digitaal-identificeren",
    path: indexqsGK3v3CE2Meta?.path ?? "/mijn-profiel/digitaal-identificeren",
    meta: indexqsGK3v3CE2Meta || {},
    alias: indexqsGK3v3CE2Meta?.alias || [],
    redirect: indexqsGK3v3CE2Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-profiel/digitaal-identificeren/index.vue").then(m => m.default || m)
  },
  {
    name: indexmxtWoZih3lMeta?.name ?? "mijn-profiel-iban-overzicht",
    path: indexmxtWoZih3lMeta?.path ?? "/mijn-profiel/iban-overzicht",
    meta: indexmxtWoZih3lMeta || {},
    alias: indexmxtWoZih3lMeta?.alias || [],
    redirect: indexmxtWoZih3lMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-profiel/iban-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexqygFE6nOI1Meta?.name ?? "mijn-profiel",
    path: indexqygFE6nOI1Meta?.path ?? "/mijn-profiel",
    meta: indexqygFE6nOI1Meta || {},
    alias: indexqygFE6nOI1Meta?.alias || [],
    redirect: indexqygFE6nOI1Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-profiel/index.vue").then(m => m.default || m)
  },
  {
    name: indexA1zmBEOfwuMeta?.name ?? "mijn-profiel-naw-gegevens",
    path: indexA1zmBEOfwuMeta?.path ?? "/mijn-profiel/naw-gegevens",
    meta: indexA1zmBEOfwuMeta || {},
    alias: indexA1zmBEOfwuMeta?.alias || [],
    redirect: indexA1zmBEOfwuMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mijn-profiel/naw-gegevens/index.vue").then(m => m.default || m)
  },
  {
    name: indexbRC0FZjFPlMeta?.name ?? "mogelijk-fonds-id-deelname",
    path: indexbRC0FZjFPlMeta?.path ?? "/mogelijk-fonds/:id()/deelname",
    meta: indexbRC0FZjFPlMeta || {},
    alias: indexbRC0FZjFPlMeta?.alias || [],
    redirect: indexbRC0FZjFPlMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mogelijk-fonds/[id]/deelname/index.vue").then(m => m.default || m)
  },
  {
    name: indexGKbCM3HM5AMeta?.name ?? "mogelijk-fonds-id-documenten",
    path: indexGKbCM3HM5AMeta?.path ?? "/mogelijk-fonds/:id()/documenten",
    meta: indexGKbCM3HM5AMeta || {},
    alias: indexGKbCM3HM5AMeta?.alias || [],
    redirect: indexGKbCM3HM5AMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mogelijk-fonds/[id]/documenten/index.vue").then(m => m.default || m)
  },
  {
    name: indexrgDotvdPdgMeta?.name ?? "mogelijk-fonds-id",
    path: indexrgDotvdPdgMeta?.path ?? "/mogelijk-fonds/:id()",
    meta: indexrgDotvdPdgMeta || {},
    alias: indexrgDotvdPdgMeta?.alias || [],
    redirect: indexrgDotvdPdgMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mogelijk-fonds/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indextIUpDhxCf3Meta?.name ?? "mogelijk-fonds-id-inschrijving",
    path: indextIUpDhxCf3Meta?.path ?? "/mogelijk-fonds/:id()/inschrijving",
    meta: indextIUpDhxCf3Meta || {},
    alias: indextIUpDhxCf3Meta?.alias || [],
    redirect: indextIUpDhxCf3Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mogelijk-fonds/[id]/inschrijving/index.vue").then(m => m.default || m)
  },
  {
    name: indexy7IMmothDAMeta?.name ?? "mogelijk-fonds-id-ondertekenen",
    path: indexy7IMmothDAMeta?.path ?? "/mogelijk-fonds/:id()/ondertekenen",
    meta: indexy7IMmothDAMeta || {},
    alias: indexy7IMmothDAMeta?.alias || [],
    redirect: indexy7IMmothDAMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mogelijk-fonds/[id]/ondertekenen/index.vue").then(m => m.default || m)
  },
  {
    name: indexM3FQe8YIVZMeta?.name ?? "mogelijk-fonds-id-overmaken",
    path: indexM3FQe8YIVZMeta?.path ?? "/mogelijk-fonds/:id()/overmaken",
    meta: indexM3FQe8YIVZMeta || {},
    alias: indexM3FQe8YIVZMeta?.alias || [],
    redirect: indexM3FQe8YIVZMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mogelijk-fonds/[id]/overmaken/index.vue").then(m => m.default || m)
  },
  {
    name: indexnGG4oqJjquMeta?.name ?? "mogelijk-fonds",
    path: indexnGG4oqJjquMeta?.path ?? "/mogelijk-fonds",
    meta: indexnGG4oqJjquMeta || {},
    alias: indexnGG4oqJjquMeta?.alias || [],
    redirect: indexnGG4oqJjquMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/mogelijk-fonds/index.vue").then(m => m.default || m)
  },
  {
    name: errorl4GapDjml4Meta?.name ?? "ockto-hash-error",
    path: errorl4GapDjml4Meta?.path ?? "/ockto/:hash()/error",
    meta: errorl4GapDjml4Meta || {},
    alias: errorl4GapDjml4Meta?.alias || [],
    redirect: errorl4GapDjml4Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/ockto/[hash]/error.vue").then(m => m.default || m)
  },
  {
    name: indexDAgjycjDx0Meta?.name ?? "ockto-hash",
    path: indexDAgjycjDx0Meta?.path ?? "/ockto/:hash()",
    meta: indexDAgjycjDx0Meta || {},
    alias: indexDAgjycjDx0Meta?.alias || [],
    redirect: indexDAgjycjDx0Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/ockto/[hash]/index.vue").then(m => m.default || m)
  },
  {
    name: qrcodeS6R8X8gDaNMeta?.name ?? "ockto-hash-qrcode",
    path: qrcodeS6R8X8gDaNMeta?.path ?? "/ockto/:hash()/qrcode",
    meta: qrcodeS6R8X8gDaNMeta || {},
    alias: qrcodeS6R8X8gDaNMeta?.alias || [],
    redirect: qrcodeS6R8X8gDaNMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/ockto/[hash]/qrcode.vue").then(m => m.default || m)
  },
  {
    name: succeswuoI46DVRMMeta?.name ?? "ockto-hash-succes",
    path: succeswuoI46DVRMMeta?.path ?? "/ockto/:hash()/succes",
    meta: succeswuoI46DVRMMeta || {},
    alias: succeswuoI46DVRMMeta?.alias || [],
    redirect: succeswuoI46DVRMMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/ockto/[hash]/succes.vue").then(m => m.default || m)
  },
  {
    name: _91token_935uXL8nSVjxMeta?.name ?? "onboarding-signing_id-token",
    path: _91token_935uXL8nSVjxMeta?.path ?? "/onboarding/:signing_id()/:token()",
    meta: _91token_935uXL8nSVjxMeta || {},
    alias: _91token_935uXL8nSVjxMeta?.alias || [],
    redirect: _91token_935uXL8nSVjxMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/onboarding/[signing_id]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Y3S2q00l9DMeta?.name ?? "onboarding-slug",
    path: _91slug_93Y3S2q00l9DMeta?.path ?? "/onboarding/:slug()",
    meta: _91slug_93Y3S2q00l9DMeta || {},
    alias: _91slug_93Y3S2q00l9DMeta?.alias || [],
    redirect: _91slug_93Y3S2q00l9DMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/onboarding/[slug].vue").then(m => m.default || m)
  },
  {
    name: aanvraagblflfV030CMeta?.name ?? "onboarding-aanvraag",
    path: aanvraagblflfV030CMeta?.path ?? "/onboarding/aanvraag",
    meta: aanvraagblflfV030CMeta || {},
    alias: aanvraagblflfV030CMeta?.alias || [],
    redirect: aanvraagblflfV030CMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/onboarding/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: _91experience_930TNIz3LAbEMeta?.name ?? "onboarding-bedankt-experience",
    path: _91experience_930TNIz3LAbEMeta?.path ?? "/onboarding/bedankt/:experience()",
    meta: _91experience_930TNIz3LAbEMeta || {},
    alias: _91experience_930TNIz3LAbEMeta?.alias || [],
    redirect: _91experience_930TNIz3LAbEMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/onboarding/bedankt/[experience].vue").then(m => m.default || m)
  },
  {
    name: _91token_93V8SXHs3uGAMeta?.name ?? "onfido-token",
    path: _91token_93V8SXHs3uGAMeta?.path ?? "/onfido/:token()",
    meta: _91token_93V8SXHs3uGAMeta || {},
    alias: _91token_93V8SXHs3uGAMeta?.alias || [],
    redirect: _91token_93V8SXHs3uGAMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/onfido/[token].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93kd76prWZ1tMeta?.name ?? "passeren-hash",
    path: _91hash_93kd76prWZ1tMeta?.path ?? "/passeren/:hash()",
    meta: _91hash_93kd76prWZ1tMeta || {},
    alias: _91hash_93kd76prWZ1tMeta?.alias || [],
    redirect: _91hash_93kd76prWZ1tMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/passeren/[hash].vue").then(m => m.default || m)
  },
  {
    name: indexmwbb9VPXjAMeta?.name ?? "privacy-verklaring",
    path: indexmwbb9VPXjAMeta?.path ?? "/privacy-verklaring",
    meta: indexmwbb9VPXjAMeta || {},
    alias: indexmwbb9VPXjAMeta?.alias || [],
    redirect: indexmwbb9VPXjAMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/privacy-verklaring/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93zxWrTiNXFHMeta?.name ?? "rentevoorstel-hash",
    path: _91hash_93zxWrTiNXFHMeta?.path ?? "/rentevoorstel/:hash()",
    meta: _91hash_93zxWrTiNXFHMeta || {},
    alias: _91hash_93zxWrTiNXFHMeta?.alias || [],
    redirect: _91hash_93zxWrTiNXFHMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/rentevoorstel/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91fundKey_93Wteg3JiTUYMeta?.name ?? "sso-exchangeToken-fundKey",
    path: _91fundKey_93Wteg3JiTUYMeta?.path ?? "/sso/:exchangeToken()/:fundKey()",
    meta: _91fundKey_93Wteg3JiTUYMeta || {},
    alias: _91fundKey_93Wteg3JiTUYMeta?.alias || [],
    redirect: _91fundKey_93Wteg3JiTUYMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/sso/[exchangeToken]/[fundKey].vue").then(m => m.default || m)
  },
  {
    name: aanvraagU1OiJkhWK1Meta?.name ?? "sso-exchangeToken-onboarding-aanvraag",
    path: aanvraagU1OiJkhWK1Meta?.path ?? "/sso/:exchangeToken()/onboarding/aanvraag",
    meta: aanvraagU1OiJkhWK1Meta || {},
    alias: aanvraagU1OiJkhWK1Meta?.alias || [],
    redirect: aanvraagU1OiJkhWK1Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/sso/[exchangeToken]/onboarding/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: indexEcLK1DgfNzMeta?.name ?? "sso-exchangeToken-onboarding",
    path: indexEcLK1DgfNzMeta?.path ?? "/sso/:exchangeToken()/onboarding",
    meta: indexEcLK1DgfNzMeta || {},
    alias: indexEcLK1DgfNzMeta?.alias || [],
    redirect: indexEcLK1DgfNzMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/sso/[exchangeToken]/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93U43tVIK1x0Meta?.name ?? "sso-token-id",
    path: _91id_93U43tVIK1x0Meta?.path ?? "/sso/:token()/:id()",
    meta: _91id_93U43tVIK1x0Meta || {},
    alias: _91id_93U43tVIK1x0Meta?.alias || [],
    redirect: _91id_93U43tVIK1x0Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/sso/[token]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexvwJKHv3G2wMeta?.name ?? "sso-token",
    path: indexvwJKHv3G2wMeta?.path ?? "/sso/:token()",
    meta: indexvwJKHv3G2wMeta || {},
    alias: indexvwJKHv3G2wMeta?.alias || [],
    redirect: indexvwJKHv3G2wMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/sso/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_939vPfT7SYFoMeta?.name ?? "verzekeringen-hash",
    path: _91hash_939vPfT7SYFoMeta?.path ?? "/verzekeringen/:hash()",
    meta: _91hash_939vPfT7SYFoMeta || {},
    alias: _91hash_939vPfT7SYFoMeta?.alias || [],
    redirect: _91hash_939vPfT7SYFoMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250403082633/packages/investors/pages/verzekeringen/[hash].vue").then(m => m.default || m)
  }
]